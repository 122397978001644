export const MGL_ACCESS_TOKEN = 'pk.eyJ1IjoibGVhbm1lZGlhIiwiYSI6ImNrMW05d3NjbjA5anIzaHFqejcycGJ5bjgifQ.8fEHqDibQ7XoyEhaucRQ4g';
export const GTM_CONTAINER_ID = 'GTM-PSMB9HC';

export const MGL_MAP_STYLES = {
    pois: {
        style: 'mapbox://styles/leanmedia/ck55s4m2t01mo1ck4db4jb03x',
        center: [-98.583333, 39.833333],
        zoom: 3.5,
    },
    albers: {
        style: 'mapbox://styles/leanmedia/ckz08jies001d16l6atnscfcw',
        center: [0, 0],
        zoom: 3,
    },
    mercator: {
        style: 'mapbox://styles/leanmedia/clwi0zo9w02rs01nx5j5ld682',
        center: [-96.1, 38],
        zoom: 3.6,
        bounds: [
            [-126.50266451437082, 23.374833239195297], // southwestern corner of the bounds
            [-65.37639455575616, 49.18290675533936] // northeastern corner of the bounds
        ],
    },
};

export const DATA_CUTOFF_DATE= '2023-01-01';
export const DEFAULT_FILTER_GROUPS = ['Primary Conversions', 'Offline Visit Conversions'];
export const DEFAULT_FILTER_GROUPS_COLUMN = 'tracking_tag_id';

export const SORTABLE_CHARTS = ['bar', 'horizontalBar'];
export const STACKABLE_CHARTS = ['bar', 'horizontalBar', 'area'];
export const LEGEND_CHARTS = ['bar', 'horizontalBar', 'line', 'area'];
export const ORDERABLE_CHARTS = ['data_table'];
export const FILTERABLE_CHARTS = ['data_table'];
